import { trackEvent } from '@centered/analytics'
import Link from 'next/link'

interface TryCenteredButtonProps {
  source: string
}

export default function TryCenteredButton({
  source,
}: TryCenteredButtonProps): JSX.Element {
  return (
    <Link href="/teams/signup">
      <Button
        onClick={() => {
          trackEvent('HomePageCTAClicked', {
            ctaSource: source,
          })
        }}
      >
        Learn more for free
      </Button>
    </Link>
  )
}

function Button({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element {
  return (
    <div
      {...props}
      className="button tryCenteredButton inline-block cursor-pointer text-white font-normal text-lg px-10 py-3 rounded-full"
    >
      <style jsx>{`
        .button {
          background: linear-gradient(95.64deg, #d2116b 0%, #e2341c 100%);
        }

        .button:hover {
          background: #9f1a4b;
        }
      `}</style>

      {children}
    </div>
  )
}
