import React from 'react'

interface WrapperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  disablePadding?: boolean
}

export default function Wrapper({
  className,
  disablePadding = false,
  ...props
}: WrapperProps): JSX.Element {
  return (
    <div
      className={`w-full ${
        disablePadding ? 'lg:px-8' : 'px-8'
      } max-w-6xl mx-auto ${className}`}
      {...props}
    />
  )
}
