import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

type Props = {
  href: string
  text: string | JSX.Element
  onClick?: () => void
  className?: string
  activeClassName?: string
}

const ActiveLink: React.FunctionComponent<Props> = ({
  href,
  text,
  onClick = () => {},
  className = '',
  activeClassName = '',
}) => {
  const router = useRouter()

  const isActive = href.includes('blog')
    ? router.pathname.startsWith(href)
    : router.pathname === href

  return (
    <Link
      href={href}
      className={`${className} ${isActive ? activeClassName : ''}`}
      onClick={onClick}
      target={href.startsWith('http') ? '_blank' : '_self'}
    >
      {text}
    </Link>
  )
}

export default ActiveLink
