import { identify, trackEvent } from '@centered/analytics'
import { getCookie } from '@centered/auth'
import { useInvitedByUserId } from '@centered/components/dist/hooks/useInvitedByUserId'
import SpinnerIcon from '@centered/components/dist/icons/SpinnerIcon'
import { auth, createUser, firebase } from '@centered/firebase'
import { checkIsMobile } from '@centered/helpers'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState, useCallback } from 'react'

interface GoogleAuthCtaProps {
  source: string
  noGoogleAccountSource: string
}

export default function GoogleAuthCta({
  source,
  noGoogleAccountSource,
}: GoogleAuthCtaProps): JSX.Element {
  const [invitedByUserId] = useInvitedByUserId()
  const router = useRouter()
  const [loggingIn, setLoggingIn] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (!user) return
      console.debug('[GoogleAuthCta:onAuthStateChanged] has user', user)
      setLoggingIn(true)
      const { uid, displayName, email, picture } = user
      const fbp = getCookie('_fbp')
      const fbc = getCookie('_fbc')
      const ttclid = getCookie('ttclid')
      const gclaw = getCookie('_gcl_aw')?.split('.')
      const gclid = gclaw ? gclaw[gclaw.length - 1] : undefined

      identify(uid, {
        id: uid,
        name: displayName,
        email: email,
      })

      console.debug('[GoogleAuthCta:onAuthStateChanged] calling createUser')
      const isNewUser = await createUser(displayName, {
        avatarUrl: picture,
        invitedByUserId: invitedByUserId ?? undefined,
        conversionTracking: {
          fbc,
          fbp,
          gclid,
          ttclid,
        },
      })
      trackEvent(isNewUser ? 'SignUp' : 'Login', {
        id: uid,
        name: displayName,
        email: email,
        authMethod: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        referredBy: invitedByUserId ?? undefined,
        fbp,
        fbc,
        gclid,
        ttclid,
        isMobile: checkIsMobile(),
      })

      router.push('/app')
    })
    return () => unsubscribe()
  }, [])

  const handleGoogleLogin = useCallback(async () => {
    trackEvent('HomePageCTAClicked', {
      ctaSource: source,
    })
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
    await auth.signInWithPopup(provider).catch((e) => {
      Object.keys(e).forEach((key) => {
        setError((prev) => `${prev} ${key}: ${e[key]}<br />`)
      })
    })
  }, [source])

//   return (
//     <div className="inline-flex flex-col items-center gap-2">
//       <div dangerouslySetInnerHTML={{ __html: error }} />

//      <div
//   onClick={handleGoogleLogin}
//   className="button tryCenteredButton flex cursor-pointer text-white font-normal text-lg px-6 md:px-8 py-3 rounded-full gap-2 items-center"
// >
//   <style jsx>{`
//     .button {
//       background: linear-gradient(95.64deg, #d2116b 0%, #e2341c 100%);
//     }

//     .button:hover {
//       background: #9f1a4b;
//     }
//   `}</style>

//   {loggingIn ? <SpinnerIcon /> : ''}
//   <p className="whitespace-nowrap">Start Free Trial with <FontAwesomeIcon icon={faGoogle} />oogle</p>
// </div>



//       <Link
//         href="/auth/login?emailOnly=true"
//         onClick={() => {
//           trackEvent('HomePageCTAClicked', {
//             ctaSource: noGoogleAccountSource,
//           })
//         }}
//         className="text-white text-sm text-opacity-60"
//       >
//         Let's use my email instead.
//       </Link>
//     </div>
//   )
// }
return (
  <div className="inline-flex flex-col items-center gap-2">
    <div dangerouslySetInnerHTML={{ __html: error }} />

    <div
      onClick={() => {
        trackEvent('HomePageCTAClicked', {
          ctaSource: source,
        });
        router.push("/auth/login"); // Michael changed this to route to the login page, while maintaining button and button styling below
      }}
      className="button tryCenteredButton flex cursor-pointer text-white font-normal text-lg px-6 md:px-8 py-3 rounded-full gap-2 items-center"
    >
      <style jsx>{`
        .button {
          background: linear-gradient(95.64deg, #d2116b 0%, #e2341c 100%);
        }
        .button:hover {
          background: #9f1a4b;
        }
      `}</style>

      {loggingIn ? <SpinnerIcon /> : ''}
      <p className="whitespace-nowrap">Try it for Free!</p>
    </div>
  </div>
);
}
