import Image from 'next/image'
import MountainRangeImage from './assets/mountain-range.jpg'
import Wrapper from './Wrapper'

export const HEADER_ADJUSTING_STYLES = {
  marginTop: -96,
  paddingTop: 96,
}

interface PageHeaderProps {
  title: string
  subtitle?: string
}

export default function PageHeader({
  title,
  subtitle,
}: PageHeaderProps): JSX.Element {
  return (
    <div
      style={HEADER_ADJUSTING_STYLES}
      className="relative text-white h-96 flex justify-end items-end"
    >
      <Image
        src={MountainRangeImage}
        role="presentation"
        alt=""
        fill
        className="object-cover object-center"
      />

      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50" />

      <Wrapper>
        <div className="relative z-20 ">
          <h2 className="text-xl">{subtitle}</h2>
          <h1 className="pb-10 text-5xl font-medium">{title}</h1>
        </div>
      </Wrapper>
    </div>
  )
}
