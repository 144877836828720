import GoogleAuthCta from '../shared/GoogleAuthCta'

interface TryCenteredButtonProps {
  source: string
  noGoogleAuthSource: string
}

export default function TryCenteredButton({
  source,
  noGoogleAuthSource,
}: TryCenteredButtonProps): JSX.Element {
  return (
    <GoogleAuthCta source={source} noGoogleAccountSource={noGoogleAuthSource} />
  )
}
