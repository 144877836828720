import Link from 'next/link'

export default function TryCenteredButton(): JSX.Element {
  return (
    <Link href="/auth/login">
      <PinkButton>
        <b className="font-semibold">Try Sukha</b> for Free
      </PinkButton>
    </Link>
  )
}

export function PinkButton({
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element {
  return (
    <div
      {...props}
      className="inline-block cursor-pointer text-white font-normal px-6 py-4 text-sm md:text-base bg-gradient-to-r from-v5-pink to-v5-orange hover:bg-opacity-90 transition-colors rounded-full"
    >
      {children}
    </div>
  )
}
